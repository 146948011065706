.studentDetailsContainer {
    display: flow;
    align-items: flex-start;
}
.mainDiv{
    margin: 0px;
}
.studentImage {
    min-width: 150px;
    min-height: 150px;
    margin-left: 20px;
    margin-right: 20px; /* Расстояние между изображением и текстом */
}
